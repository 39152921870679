/* @import '../../styles/variables.scss'; */

.btn--primary {
  cursor: pointer;
  display: inline-block;
  width: 100%;
  /* height: 4.4rem; */
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.7rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #200e6b;
  margin-bottom: 20px;

  &:disabled {
    background-color: #ccc;
  }
}
