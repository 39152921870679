.table-container {
  width: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  input, textarea, select,
  .wrapper-select {
    margin: 0;
    font-size: 12px;
  }

  input[type="checkbox"] {
    margin: 0 auto;
    display: block;
    padding: 0;
    width: auto;
    height: auto;
  }

  .isChecked {
    background: rgb(239, 109, 109) !important;
  }

  .isDeleting {
    td {
      background: red !important;
    }
  }

  .paid {
    td {
      background: rgb(115, 211, 226);
    }
  }

  .hasConciliation {
    td {
      background: rgb(115, 226, 142);
    }
  }

  button {
    // background: transparent;
    // border: 0;
    margin: auto;
    display: block;
    cursor: pointer;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    thead {
      tr:first-child {
        display: none;
      }
      th {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1;
        table-layout: fixed;
      }
    }
    th, td {
      // padding: 5px 8px;
      padding: 2px 4px;
      text-align: left;
      background-color: #fff;
      border-bottom: 1px solid #e0e0e0;
      font-size: 11px;
      img {
        display: block;
        width: 40px;
        height: auto;
        margin: 0 auto;
      }
    }

    th {
      background-color: #ccc !important;
      font-weight: bold;
    }

    tbody tr:hover {
      background-color: #f5f5f5;
    }

    tbody {
      .tfoot {
        display: none;
      }
    }

    tfoot {
      position: sticky;
      bottom: 0;
      background-color: white;
      z-index: 1;
      width: 20px;
      text-align: left;
      table-layout: fixed;
      tr:last-child {
        td {
          background: #ccc;
        }
      }
    }
  }
}