.conciliation-screen {
  .css-3w2yfm-ValueContainer {
    height: 35px;
    overflow-y: auto;
  }
  .form-box {
    margin-bottom: 20px;
    max-width: 70vw;
    padding: 0px 20px 20px;
    float: right;
    margin-right: 20px;
  }
  &.wrapper-content {
    flex-flow: column;
  }
  .wrapper-columns {
    display: flex;
    flex-wrap: wrap; /* Allow columns to wrap to the next line if needed */
    justify-content: space-between; /* Align columns with space in between */

    .column:first-child {
      max-width: 185px;
    }
  }

  .inputDate {
    margin: 0;
  }

  .search-bar {
    width: 100%; /* Set width to 100% to fill the entire container */
    box-sizing: border-box; /* Include padding and border in the total width */
    position: relative;

    .column {
      flex: 1; /* Each column takes equal width */
      margin-right: 20px; /* Add some right margin between columns */
      padding-top: 65px;
      &:last-child {
        margin: 0;
      }

      // &:first-child {
      //   max-width: 155px !important;
      //   padding-top: 0;
      // }
    }

    label {
      width: 100%; /* Make labels take full width */
      text-align: left; /* Align labels to the left */
      font-size: 12px;
      display: block;

      span {
        margin: 15px 0 5px;
        display: inline-block;
      }
    }

    button {
      margin: 36px 0 0; /* Adjust button margin */
      padding: 4px;
      background-color: #291671;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background-color: #291671;
      }
    }

    select,
    input[type="text"],
    input[type="date"] {
      min-width: 150px;
      margin: 0;
      padding: 9px 16px 10px;
    }

    input {
      width: auto;
      margin: 0;
    }

    input,
    textarea,
    select {
      background: none;
      box-shadow: none;
      display: block;
      border-color: hsl(0, 0%, 80%);
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 0px 0px 10px 2.5%;
    position: absolute;
    top: 77px;
    left: -25px;
  }

  .table-container {
    width: 98vw;
    margin: 0 auto;
  }

  .print {
    display: none;
  }

  @media print {
    @page {
      size: landscape;
    }

    [data-show="print"],
    .print {
      display: table-cell;
      font-size: 9px;
    }

    .no-print {
      display: none !important;
    }

    h6.print {
      display: block;
    }

    body {
      padding: 0;
    }

    table {
      width: 100%;
      height: 100vh;
      overflow: hidden;
      border-collapse: collapse;

      input,
      textarea,
      select {
        border: 0;
        background-color: transparent;
        box-shadow: none;
        font-size: 9px;
        height: auto;
        padding: 0;
      }
    }

    input[type="checkbox"] {
      appearance: none;
    }

    input[type="checkbox"]:checked::before {
      content: "X";
    }

    th,
    td {
      border: 1px solid #ccc;
      padding: 2px !important;
      text-align: left !important;
      background: transparent !important;
      box-shadow: none !important;
      input {
        padding: 0 5px;
        background: transparent !important;
        text-align: left !important;
      }
    }

    th {
      background-color: lightgray;
      font-size: 8px !important;
      text-align: left !important;
    }

    .form-box,
    .pagination,
    .wrapper-select:after,
    [data-show="no-print"],
    a {
      display: none;
    }

    tfoot {
      .no-print {
        display: none !important;
      }

      &.print {
        width: 100%;
      }
    }

    .table-container {
      max-width: 100%;
      box-shadow: none;
      overflow: hidden !important;
      max-height: 100% !important;
      margin-top: 20px;

      table {
        table-layout: auto;
        height: 100%;
      }
    }

    th:nth-child(7) {
      content: "Data de Pagamento";
    }
  }
}

@media print {
  tr {
    height: auto !important;
    th,
    td {
      border: 1px solid #ccc !important;
    }

    input {
      padding: 0 !important;
    }
  }

  .table-container {
    box-shadow: none !important;
  }

  .header__tabs-container {
    display: none;
  }

  span.print {
    display: block;
    font-size: 8px;
    color: #636c72;
    font-weight: normal;
  }

  th[data-show="print print-1"] {
    font-size: 8px !important;
  }

  * {
    th[data-show="print print-1"]:nth-child(1) {
      width: 100px !important;
    }
    th[data-show="print print-1"]:nth-child(2) {
      width: 100px !important;
    }
    th[data-show="print print-1"]:nth-child(3) {
      width: 100px !important;
    }
    th[data-show="print print-1"]:nth-child(4) {
      width: 40px !important;
    }
    th[data-show="print print-1"]:nth-child(7) {
      width: 40px !important;
    }
    th[data-show="print print-1"]:nth-child(9) {
      width: 40px !important;
    }
    th[data-show="print print-1"]:nth-child(18) {
      width: 140px !important;
    }
  }
}

tr {
  position: relative;
  height: 40px;
}

.row-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.row-loader img {
  width: 50px;
  height: 50px;
}

.form-box {
  position: relative;
  .wrapper-labels {
    position: absolute;
    top: 15px;
    left: 20px;
    text-align: left;
    gap: 20px;
    display: flex;
    .label-radio {
      display: flex;
      align-items: left;
      justify-content: center;
      margin-top: 10px;
      margin: 15px 0px 20px;
      input {
        width: 20px;
        position: relative;
        top: -2px;
      }
    }
  }
}
