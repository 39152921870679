.expenses-screen {
  label {
    text-align: left;
    font-size: 12px;
    display: block;

    .css-13cymwt-control,
    .css-t3ipsp-control {
      margin-top: 5px;

      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      color: #636c72;
      border-color: #e2e8f0;
      background-color: white;
    }
  }
  
  &__form {
    width: 100%;
    margin: 0 auto;
  }
  
  h2 {
    margin-top: 0;
  }

  input, textarea, select {
    padding: 10px 8px;
  }
}