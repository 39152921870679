.App {
  text-align: center;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

.App-header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

input,
textarea,
select {
  // width: calc(100% - 32px);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  display: inline-block;
  border: 1px solid;
  border-radius: 0.25rem;
  margin: 5px 0 20px;
  padding: 8px 8px;
  outline: 0;
  text-align: left;
  font: inherit;
  font-size: 13px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  color: #636c72;
  border-color: #e2e8f0;
  background-color: white;
  transition: all 200ms;

  &.has-error {
    border-color: red;
    color: red;
  }
}
input[type="date"],
select {
  -webkit-appearance: none;
}

.wrapper-select.has-error {
  .css-13cymwt-control,
  .css-t3ipsp-control {
    border-color: red;
    color: red;
  }
}

input[type="checkbox"],
input[type="radio"] {
  box-shadow: none;
}

select {
  width: 100%;
  -webkit-appearance: none;
  margin-bottom: 0;
}

.wrapper-select {
  position: relative;
  margin-bottom: 20px;
  &:after {
    position: absolute;
    top: 55%;
    right: 10px;
    content: "\25bc";
    color: gray;
    transform: translateY(-50%);
    display: block;
    font-size: 12px;
    background: #fff;
    padding: 5px;
    pointer-events: none;
  }
}

ul,
ol {
  padding: 0;
  margin: 0;
}

textarea {
  height: 150px;
  resize: none;
  margin-bottom: 20px;
}

.wrapper-content {
  width: 100vw;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.form-box {
  display: block;
  background-color: #fff;
  width: calc(100% - 80px);
  max-width: 650px;
  margin: 20px auto 0;
  padding: 40px;
  box-shadow: 0 2px 10px 0 rgba(94, 86, 105, 0.1);
  border-radius: 6px;
  color: #291671;
}

.conciliation-screen {
  &.supplier-construction-screen {
    .form-box {
      margin: 20px 0;
    }
  }
}

.wrapper-columns {
  display: flex;

  @media only screen and (max-width: 1024px) {
    display: block;
  }

  .column {
    flex: 0 0 calc(50% - 10px);
    &:first-child {
      margin-right: 20px;
      @media only screen and (max-width: 1024px) {
        margin: 0;
      }
    }
  }
}

.pagination {
  button {
    background-color: #291671;
    color: white;
    border: none;
    padding: 11px 15px;
    margin: 2px 5px 0;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: #291671;
    }
  }

  .month {
    margin: 0 10px;
    font-size: 18px;
    font-weight: bold;
  }
}

.error-message {
  color: red;
  margin: 20px 0;
}

.btn-add-more {
  background-color: #291671;
  color: #fff;
  border-radius: 50%;
  font-weight: bold;
  font-size: 15px;
  width: 22px;
  height: 22px;
  padding: 0;
  border: 0;
}

.print-button {
  background-color: none;
  border: 0;
  margin: 20px auto 0;
}

@media print {
  @page {
    size: landscape;
  }
  .reports {
    ul,
    .pagination,
    .print-button,
    .btn-add-more {
      display: none !important;
    }
  }

  input {
    border: 0 !important;
    box-shadow: none !important;
  }

  tbody {
    .tfoot {
      display: table-row !important;
    }
  }
}
